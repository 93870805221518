export const Sports = <const>[
  'account_option_running',
  'account_option_tennis',
  'account_option_padelTennis',
  'account_option_valleyball',
  'account_option_soccer',
  'account_option_futsal',
  'account_option_basketball',
  'account_option_trackAndField',
  'account_option_baseball',
  'account_option_softball',
  'account_option_handball',
  'account_option_badminton',
  'account_option_tableTennis',
  'account_option_netball',
  'account_option_rugby',
  'account_option_wrestling',
  'account_option_golf',
  'account_option_hockey',
  'account_option_lacrosse',
  'account_option_americanFootball',
  'account_option_cricket',
  'account_option_skateBoarding',
  'account_option_squash',
  'account_option_climbing',
  'account_option_trailRunning',
  'account_option_dance',
  'account_option_swimming',
  'account_option_cycling',
];

export type SportsType = (typeof Sports)[number];
