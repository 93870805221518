// 実装の都合上サーバー側のEnum定義と 回答しない の位置が変わってしまうので、 services/account で変換メソッドを定義している
export const Gender = <const>[
  'account_option_boy',
  'account_option_girl',
  'account_option_notAnswer',
];

export type GenderType = (typeof Gender)[number];

export enum GENDER_ENUM {
  Other,
  Male,
  Female,
}
