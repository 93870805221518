import styled from 'styled-components';
import { Loading } from '~/components/loading/Loading';
import { vh, vw } from '~/styles/ts/functions';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: ${vh(100)};
  width: ${vw(100)};
  overflow: hidden;
  z-index: 1000000;
`;
interface Props {
  color: string;
}

export const LoadingPresenter = ({ color }: Props) => {
  return (
    <Container>
      <Loading color={color} />
    </Container>
  );
};
