import type { PageRegion } from '~/interfaces/common';

const BASE_PATH = '/';
const BASE_URL = `${process.env.NEXT_PUBLIC_BASE_URL ?? ''}${BASE_PATH}`;

const metaData = {
  title: 'ASICS STEPNOTE | ASICS | アシックス japan',
  description:
    'あしの大きさを遊ぶように楽しく記録することで、適切な靴のサイズを、適切なタイミングでお知らせする、ちょっとおせっかいな成長予測ツールです。',
  keywords: ['ASICS', 'STEPNOTE'].join(),
  basePath: BASE_PATH,
  url: BASE_URL,
  ogImage: (lang: PageRegion) => BASE_URL + `ogp_${lang}.png`,
  faviconPath: '/favicon-v2.ico',
  appleTouchIconPath: '/apple-touch-icon.png',
  applicationName: 'STEPNOTE',
};

const twitterMeta = {
  title: metaData.title,
  description: metaData.description,
  image: (lang: PageRegion) => metaData.ogImage(lang),
  card: 'summary_large_image',
};

export const meta = {
  ...metaData,
  twitter: twitterMeta,
};
