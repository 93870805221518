export const PrefectureMap = [
  { id: 1, key: 'account_option_pref_hokkaido' },
  { id: 2, key: 'account_option_pref_aomori' },
  { id: 3, key: 'account_option_pref_iwate' },
  { id: 4, key: 'account_option_pref_miyagi' },
  { id: 5, key: 'account_option_pref_akita' },
  { id: 6, key: 'account_option_pref_yamagata' },
  { id: 7, key: 'account_option_pref_fukushima' },
  { id: 8, key: 'account_option_pref_ibaraki' },
  { id: 9, key: 'account_option_pref_tochigi' },
  { id: 10, key: 'account_option_pref_gunma' },
  { id: 11, key: 'account_option_pref_saitama' },
  { id: 12, key: 'account_option_pref_chiba' },
  { id: 13, key: 'account_option_pref_tokyo' },
  { id: 14, key: 'account_option_pref_kanagawa' },
  { id: 15, key: 'account_option_pref_niigata' },
  { id: 16, key: 'account_option_pref_toyama' },
  { id: 17, key: 'account_option_pref_ishikawa' },
  { id: 18, key: 'account_option_pref_fukui' },
  { id: 19, key: 'account_option_pref_yamanashi' },
  { id: 20, key: 'account_option_pref_nagano' },
  { id: 21, key: 'account_option_pref_gifu' },
  { id: 22, key: 'account_option_pref_shizuoka' },
  { id: 23, key: 'account_option_pref_aichi' },
  { id: 24, key: 'account_option_pref_mie' },
  { id: 25, key: 'account_option_pref_shiga' },
  { id: 26, key: 'account_option_pref_kyoto' },
  { id: 27, key: 'account_option_pref_osaka' },
  { id: 28, key: 'account_option_pref_hyogo' },
  { id: 29, key: 'account_option_pref_nara' },
  { id: 30, key: 'account_option_pref_wakayama' },
  { id: 31, key: 'account_option_pref_tottori' },
  { id: 32, key: 'account_option_pref_shimane' },
  { id: 33, key: 'account_option_pref_okayama' },
  { id: 34, key: 'account_option_pref_hiroshima' },
  { id: 35, key: 'account_option_pref_yamaguchi' },
  { id: 36, key: 'account_option_pref_tokushima' },
  { id: 37, key: 'account_option_pref_kagawa' },
  { id: 38, key: 'account_option_pref_ehime' },
  { id: 39, key: 'account_option_pref_kochi' },
  { id: 40, key: 'account_option_pref_fukuoka' },
  { id: 41, key: 'account_option_pref_saga' },
  { id: 42, key: 'account_option_pref_nagasaki' },
  { id: 43, key: 'account_option_pref_kumamoto' },
  { id: 44, key: 'account_option_pref_oita' },
  { id: 45, key: 'account_option_pref_miyazaki' },
  { id: 46, key: 'account_option_pref_kagoshima' },
  { id: 47, key: 'account_option_pref_okinawa' },
  { id: 1000, key: 'account_option_pref_abu_dhabi' },
  { id: 1001, key: 'account_option_pref_dubai' },
  { id: 1002, key: 'account_option_pref_sharjah' },
  { id: 1003, key: 'account_option_pref_ajman' },
  { id: 1004, key: 'account_option_pref_umm_ai_quwain' },
  { id: 1005, key: 'account_option_pref_ras_ai_khaimah' },
  { id: 1006, key: 'account_option_pref_fujarah' },
  { id: 1007, key: 'account_option_pref_ar_riyadh' },
  { id: 1008, key: 'account_option_pref_makkah_ai_mukarramah' },
  { id: 1009, key: 'account_option_pref_eastern_region' },
  { id: 1010, key: 'account_option_pref_ai_madinah_ai_munawwarah' },
  { id: 1011, key: 'account_option_pref_asser' },
  { id: 1012, key: 'account_option_pref_jazan' },
  { id: 1013, key: 'account_option_pref_ai_qaseem' },
  { id: 1014, key: 'account_option_pref_tabuk' },
  { id: 1015, key: 'account_option_pref_hali' },
  { id: 1016, key: 'account_option_pref_ai_jawf' },
  { id: 1017, key: 'account_option_pref_najran' },
  { id: 1018, key: 'account_option_pref_northern_borders' },
  { id: 1019, key: 'account_option_pref_ai_bahah' },
  { id: 1020, key: 'account_option_pref_ahmadi' },
  { id: 1021, key: 'account_option_pref_ai_asmah' },
  { id: 1022, key: 'account_option_pref_farwaniya' },
  { id: 1023, key: 'account_option_pref_hawalli' },
  { id: 1024, key: 'account_option_pref_jahra' },
  { id: 1025, key: 'account_option_pref_mubarak_ai_kabeer' },
  { id: 1026, key: 'account_option_pref_diha' },
  { id: 1027, key: 'account_option_pref_ai_rayyan' },
  { id: 1028, key: 'account_option_pref_ai_wakra' },
  { id: 1029, key: 'account_option_pref_umm_slal' },
  { id: 1030, key: 'account_option_pref_ai_khor_and_ai_thakhira' },
  { id: 1031, key: 'account_option_pref_ai_shamal' },
  { id: 1032, key: 'account_option_pref_ai_daayen' },
  { id: 1033, key: 'account_option_pref_ai_sheehaniya' },
  { id: 1034, key: 'account_option_pref_muscat' },
  { id: 1035, key: 'account_option_pref_dhofar' },
  { id: 1036, key: 'account_option_pref_musandam' },
  { id: 1037, key: 'account_option_pref_ai_byraymi' },
  { id: 1038, key: 'account_option_pref_ad_dakhliyah' },
  { id: 1039, key: 'account_option_pref_ai_batinah_north' },
  { id: 1040, key: 'account_option_pref_ai_batinah_south' },
  { id: 1041, key: 'account_option_pref_ash_sharqiyah_south' },
  { id: 1042, key: 'account_option_pref_ash_sharqiyah_north' },
  { id: 1043, key: 'account_option_pref_adh_dhahirah' },
  { id: 1044, key: 'account_option_pref_al_wusta' },
  { id: 1045, key: 'account_option_pref_capital' },
  { id: 1046, key: 'account_option_pref_muharraq' },
  { id: 1047, key: 'account_option_pref_northern' },
  { id: 1048, key: 'account_option_pref_southern' },
] as const;

export const Prefecture = PrefectureMap.map((item) => item.key);

export type PrefectureKeys = (typeof Prefecture)[number];
