import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import type { User } from '~/features/account/user';
import { RecoilAtomKeys } from '~/interfaces/enums/recoilKeys';

const defaultUser = {};

const userAtom = atom<User>({
  key: RecoilAtomKeys.User,
  default: defaultUser as User,
});

const useSet = () =>
  useRecoilCallback(
    ({ set }) =>
      (user: Partial<User>) => {
        set(userAtom, (prev) => {
          return {
            ...prev,
            ...user,
          };
        });
      },
    [],
  );

const useValue = () => useRecoilValue(userAtom);

export const userActions = { useSet };
export const userGetters = { useValue };
