const RelationshipData = <const>[
  { key: 1, value: 'account_option_father' },
  { key: 2, value: 'account_option_mother' },
  { key: 3, value: 'account_option_grandFather' },
  { key: 4, value: 'account_option_grandMother' },
  { key: 0, value: 'common_option_relationship_other' },
];

export const Relationship = RelationshipData.map((v) => v.value);

export type RelationshipType = (typeof Relationship)[number];

export const getRelationshipData = (value: number) => {
  let relationship: RelationshipType = RelationshipData[0].value;

  for (const data of RelationshipData) {
    if (data.key === value) {
      relationship = data.value;
      break;
    }
  }

  return relationship;
};

export const getRelationshipKey = (value: RelationshipType) => {
  let key = 0;

  for (const data of RelationshipData) {
    if (data.value === value) {
      key = data.key;
      break;
    }
  }

  return key;
};
