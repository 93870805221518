import type { Int } from '~/interfaces/utils';
import { toInt } from '~/interfaces/utils';
import { decimalRounding } from '~/utils/number';

export interface Size {
  value: number;
  readonly unit: string;
  readonly displaySize: string;
  readonly displaySizeWithUnit: string;
}

export class ShoeSize implements Size {
  private _value = 0;
  private _unit = 'cm';

  constructor(value: number, unit = 'cm') {
    this.value = value;
    this.unit = unit;
  }

  public get value(): number {
    return this._value;
  }
  public set value(v: number) {
    this._value = v;
  }

  public get unit(): string {
    return this._unit;
  }

  private set unit(u: string) {
    this._unit = u;
  }

  public get displaySize(): string {
    if (this._unit === 'cm') {
      return this._normalize(this._value);
    }

    return String(this._value);
  }

  public get displaySizeWithUnit(): string {
    if (this._unit === 'cm') {
      return this._normalize(this._value) + this.unit;
    }

    return `${this._value}${this.unit}`;
  }

  private _normalize(n: number, digits = 1): string {
    return decimalRounding(n, digits).toString();
  }
}

export class StandardShoeSize implements Size {
  private _value = 0;
  private _unit = 'cm';

  constructor(value: number, unit = 'cm') {
    this.value = value;
    this.unit = unit;
  }

  public get value(): number {
    return this._value;
  }
  public set value(v: number) {
    this._value = v;
  }

  public get unit(): string {
    return this._unit;
  }

  private set unit(u: string) {
    this._unit = u;
  }

  public get displaySize(): string {
    if (this._unit === 'cm') {
      return this._normalize(this._value);
    }

    return String(this._value);
  }

  public get displaySizeWithUnit(): string {
    if (this._unit === 'cm') {
      return this._normalize(this._value) + this.unit;
    }

    return `${this._value}${this.unit}`;
  }

  private _normalize(n: number, digits = 1): string {
    const [i, d] = n.toString().split('.');

    const I: Int = toInt(parseFloat(i));
    const D: Int = toInt(d ? parseFloat('0.' + d) * 10 ** toInt(digits) : 0);

    return this._discretization(I, D);
  }

  private _discretization(i: number, d: number): string {
    let I = i;
    let D;
    if (d < 3) {
      D = 0;
    } else if (d < 8) {
      D = 5;
    } else {
      I++;
      D = 0;
    }

    return `${I}.${D}`;
  }
}

export class Weight implements Size {
  private _value = 0;
  private _unit = 'kg';

  constructor(value: number, unit = 'kg') {
    this.value = value;
    this.unit = unit;
  }

  public get value(): number {
    return this._value;
  }
  public set value(v: number) {
    this._value = v;
  }

  public get unit(): string {
    return this._unit;
  }

  private set unit(u: string) {
    this._unit = u;
  }

  public get displaySize(): string {
    return this._normalize(this._value);
  }

  public get displaySizeWithUnit(): string {
    return this._normalize(this._value) + this.unit;
  }

  private _normalize(n: number, digits = 1): string {
    return decimalRounding(n, digits).toString();
  }
}
export class Height implements Size {
  private _value = 0;
  private _unit = 'cm';

  constructor(value: number, unit = 'cm') {
    this.value = value;
    this.unit = unit;
  }

  public get value(): number {
    return this._value;
  }
  public set value(v: number) {
    this._value = v;
  }

  public get unit(): string {
    return this._unit;
  }

  private set unit(u: string) {
    this._unit = u;
  }

  public get displaySize(): string {
    return this._normalize(this._value);
  }

  public get displaySizeWithUnit(): string {
    return this._normalize(this._value) + this.unit;
  }

  private _normalize(n: number, digits = 1): string {
    return decimalRounding(n, digits).toString();
  }
}
