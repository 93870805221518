import type { PostAccountCampaignRequest, PostAccountCampaignResponse } from '~/api/aspida/@types';
import { sendRequest } from '~/api/sendRequest';

export const postAccountCampaign = sendRequest<
  PostAccountCampaignRequest,
  PostAccountCampaignResponse
>({
  url: '/account/campaign',
  method: 'post',
});
