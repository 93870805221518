import Head from 'next/head';
import { useLocale } from '~/hooks/useLocale';
import { meta } from '~/utils/meta';

interface Props {
  ogUrl?: string;
  ogImage?: string;
}

export const Meta = ({ ogUrl, ogImage }: Props) => {
  const { regionCode } = useLocale();
  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=no" />
      <meta name="keywords" content={meta.keywords} />
      <meta name="application-name" content={meta.applicationName} />
      <meta name="apple-mobile-web-app-title" content={meta.applicationName} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl ? meta.url + ogUrl : meta.url} />
      <meta property="og:image" content={ogImage ? meta.url + ogImage : meta.ogImage(regionCode)} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="twitter:title" content={meta.twitter.title} />
      <meta property="twitter:description" content={meta.twitter.description} />
      <meta
        property="twitter:image"
        content={ogImage ? meta.url + ogImage : meta.twitter.image(regionCode)}
      />
      <meta property="twitter:card" content={meta.twitter.card} />

      <link rel="icon" href={meta.faviconPath} />
      <link rel="canonical" href={meta.url} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="apple-touch-icon" href={meta.appleTouchIconPath} />
      {/* eslint-disable-next-line @next/next/no-page-custom-font */}
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Sen:wght@400;700;800&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
};
