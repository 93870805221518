export const Color = <const>[
  'account_option_black',
  'account_option_brown',
  'account_option_orange',
  'account_option_blue',
  'account_option_white',
  'account_option_red',
  'account_option_yellow',
  'account_option_purple',
  'account_option_grey',
  'account_option_pink',
  'account_option_gold',
  'account_option_green',
];

export type ColorType = (typeof Color)[number];

// type guard なのでanyを許容
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isColor = (v: any): v is ColorType => {
  return (
    typeof v === 'string' &&
    Color.some((p) => {
      return p === v;
    })
  );
};
