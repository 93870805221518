import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useAccount } from '~/hooks/useAccount';
import { NavRoutes } from '~/interfaces/enums/navRoutes';

export const AuthChecker = ({ children }: { children: ReactNode }) => {
  const route = useRouter();
  const { isAuth, auth } = useAccount();
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const publicPaths: string[] = [
    NavRoutes.Entry,
    NavRoutes.FAQ,
    `${NavRoutes.FAQ}/[type]`,
    NavRoutes.Withdrawn,
    `${NavRoutes.Gallery}/share/[id]`,
    NavRoutes.Scan,
  ];

  useEffect(() => {
    if (publicPaths.includes(route.pathname)) {
      setIsPublic(true);
    } else {
      auth();
    }
  }, [route]);

  return isAuth || isPublic ? <>{children}</> : <></>;
};
