import type { PostAccountRegisterRequest, PostAccountRegisterResponse } from '~/api/aspida/@types';
import { sendRequest } from '~/api/sendRequest';

export const postAccountRegister = sendRequest<
  PostAccountRegisterRequest,
  PostAccountRegisterResponse
>({
  url: '/account/register',
  method: 'post',
});
