import { useEffect, useState } from 'react';

export const useSetRegion = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isForbiddenAccess, setIsForbiddenAccess] = useState<boolean>(false);

  const isFromEuropeRegion = async () => {
    // 一時的な変更 refs
    // https://kayac.slack.com/archives/C01KNUTEH5E/p1677483918519879
    //
    // const regExp = new RegExp(`^${REGION_CONTINENT.EUROPE}$`);
    return false;
    // try {
    //   const res = await regionApi.get();
    //   const isEurope = regExp.test(res.continent);

    //   return isEurope;
    // } catch {
    //   return false;
    // }
  };

  useEffect(() => {
    (async () => {
      const isFromEurope = await isFromEuropeRegion();
      setIsForbiddenAccess(isFromEurope);
      setIsLoading(false);
    })();
  }, []);

  return { isLoading, isForbiddenAccess };
};
