import type { ChildData } from '~/api/aspida/@types';
import { accountServices } from '~/features/account/accountService';
import type { Child } from '~/features/account/child/interfaces/models';
import { Color } from '~/features/account/color';
import { Sports } from '~/features/account/sports/SportsType';
import { Height, ShoeSize, Weight } from '~/features/size/size';
import { unixTimeFromSQL } from '~/utils/time';

const childAdapter = (childData: ChildData): Child => {
  const child: Child = {
    child_id: childData.child_id,
    childname: childData.childname,
    birthday: unixTimeFromSQL(childData.birthday),
    gender: accountServices.EnumToGender(childData.gender),
    height: new Height(childData.height),
    weight: new Weight(childData.weight),
    favorite_sport: Sports[childData.favoriteSports],
    favorite_sport_text: childData.favoriteSportsText ?? '',
    favorite_color: Color[childData.favorite_color - 1],
    icon: childData.icon || '',
    right_length: new ShoeSize(childData.right_length),
    right_circumference: new ShoeSize(childData.right_circumference),
    left_length: new ShoeSize(childData.left_length),
    left_circumference: new ShoeSize(childData.left_circumference),
  };

  return child;
};

export const kidsServices = {
  childAdapter,
};
