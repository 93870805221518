import { useContext, createElement, useCallback } from 'react';
import type { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { ModalContext } from '~/context/ModalContext';
import { colors } from '~/styles/ts/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${colors.thinBlack_1};
  opacity: 1;
  z-index: 100000;
`;

export const Modal = () => {
  const { closeModal, elementQueue } = useContext(ModalContext);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.currentTarget !== e.target) return;
      closeModal();
    },
    [closeModal],
  );

  if (elementQueue.length === 0) return <></>;

  return <Container onClick={onClick}>{createElement(elementQueue[0])}</Container>;
};
