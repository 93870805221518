import type { Dispatch, SetStateAction } from 'react';

export type SetState<T> = Dispatch<SetStateAction<T>>;

interface Phase {
  phase: number;
}

export interface Page extends Phase {
  stageCount: number;
}

export interface Section extends Phase {
  sectionNames: string | string[];
}

export interface CurrentPage extends Phase {
  stage: number;
}

export type Int = number & { __int__: void };
export const toInt = (num: number): Int => Math.round(num) as Int;
