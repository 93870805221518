import { css } from 'styled-components';
import { colors } from '~/styles/ts/colors';
import { font, fontFamilies, pxVw } from '~/styles/ts/functions';

export const numberBadge = css`
  .number-badge {
    width: ${pxVw.SP(72)};
    height: ${pxVw.SP(72)};
    background-color: ${colors.textbox_blue};
    border-radius: 50%;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${fontFamilies.sen};
    font-weight: 700;
    margin: 0 ${pxVw.SP(8)};
    ${font('SP', 40, '3%')};
  }
`;
