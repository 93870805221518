export const clamp = (min: number, max: number, val: number) =>
  val < min ? min : val > max ? max : val;

export const max = (arr: number[]) => arr.reduce((max, currVal) => (currVal > max ? currVal : max));
export const min = (arr: number[]) => arr.reduce((min, currVal) => (currVal < min ? currVal : min));

export const bufferToString = (buf: ArrayBuffer): string => {
  return String.fromCharCode.apply('', Array.from<number>(new Uint16Array(buf)));
};

export const decimalRounding = (num: number, digits = 1) => {
  const div = Math.pow(10, digits);
  return Math.round(num * div) / div;
};

// num = 10の場合は10.0
// num = 12.1の場合は12.1
// といった形でdigitsの数だけ小数点以下を表示し、足りない部分は0で補完する関数
export const decimalAlignment = (num: number, digits = 1) => {
  const numString = decimalRounding(num, digits).toString();
  const zeroString = [...(Array(digits) as string[])].fill('0').join('');

  const [integer, decimal] = numString.split('.');
  if (!decimal) return `${integer}.${zeroString}`;
  return `${integer}.${(decimal + zeroString).slice(0, digits)}`;
};
