import type { PrefectureKeys } from '~/features/account/prefecture/enum';
import { PrefectureMap } from '~/features/account/prefecture/enum';

export const prefectureToNumberEnum = (p: PrefectureKeys): number => {
  return PrefectureMap.find((prefecture) => prefecture.key === p)?.id || 0;
};

export const prefectureListFromAreaIds = (areaIds: number[]) => {
  return PrefectureMap.filter((prefecture) => areaIds.includes(prefecture.id)).map(
    (prefecture) => prefecture.key,
  );
};

export const prefectureKeysFromAreaId = (areaId: number): PrefectureKeys => {
  return PrefectureMap.find((prefecture) => prefecture.id === areaId)?.key as PrefectureKeys;
};
