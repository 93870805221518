import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useAccountApi } from '~/features/account/hooks/useAccountApi';
import { useTokenApi } from '~/hooks/api/useTokenApi';
import { NavRoutes } from '~/interfaces/enums/navRoutes';
import { userGetters } from '~/recoil/user';

export const useAccount = () => {
  const { getAccountRequest } = useAccountApi();
  const user = userGetters.useValue();
  const { postTokenRefreshRequest } = useTokenApi();
  const router = useRouter();
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const auth = useCallback(async () => {
    if (user?.asics_id) {
      setIsAuth(true);
      return;
    }
    const path = router.pathname;
    if (isFetching) return;

    setIsFetching(true);

    await getAccountRequest(
      {},
      () => {
        setIsAuth(true);
      },
      async (res) => {
        const status = res?.response?.status;
        if (status === 401) {
          await postTokenRefreshRequest(
            {},
            async () => {
              await getAccountRequest(
                {},
                () => {
                  setIsAuth(true);
                },
                () => {
                  alert('ログインが必要です');
                  location.href = NavRoutes.Entry;
                },
              );
            },
            () => {
              alert('ログインが必要です');
              location.href = NavRoutes.Entry;
            },
          );
        } else if (status === 404 && path === NavRoutes.Register) {
          // 登録画面で404は正常
          setIsAuth(true);
        } else {
          location.href = NavRoutes.Entry;
        }
      },
      () => {
        setIsFetching(false);
      },
    );
  }, [router.pathname]);

  return { isAuth, auth };
};
