import type { ReactNode } from 'react';
import styled from 'styled-components';
import { ForbiddenPage } from '~/components/functional/ForbiddenPage';
import { LoadingContainer } from '~/components/loading/Index';
import { Modal } from '~/components/modals/Index';
import { useSetRegion } from '~/features/region/hooks/useSetRegion';
import { AuthChecker } from '~/layouts/common/AuthChecker';
import { Meta } from '~/layouts/common/Meta';
import { StyleClass } from '~/styles/class/StyleClass';

const LayoutContainer = styled.div`
  ${StyleClass}
  min-width: '100%';
`;

interface Props {
  children: ReactNode;
  ogImage?: string;
  ogUrl?: string;
}

export const DefaultLayout = ({ children, ogImage, ogUrl }: Props) => {
  const { isLoading, isForbiddenAccess } = useSetRegion();
  return (
    <>
      <Meta ogImage={ogImage} ogUrl={ogUrl} />
      <LayoutContainer>
        <LoadingContainer />
        <Modal />
        <AuthChecker>
          {isLoading ? <></> : isForbiddenAccess ? <ForbiddenPage /> : children}
        </AuthChecker>
      </LayoutContainer>
    </>
  );
};
