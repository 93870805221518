import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import type { Child } from '~/features/account/child/interfaces/models';
import type { ChildId } from '~/features/account/user';
import type { ID } from '~/interfaces/common';
import { RecoilAtomKeys, RecoilSelectorKeys } from '~/interfaces/enums/recoilKeys';

const childAtomFamily = atomFamily<Child, ChildId>({
  key: RecoilAtomKeys.Child,
  default: undefined,
});

const childFirstLoginAtomFamily = atomFamily<boolean, ChildId>({
  key: RecoilAtomKeys.ChildFirstLogin,
  default: false,
});

const childIdsAtom = atom<ChildId[]>({
  key: RecoilAtomKeys.ChildIds,
  default: [],
});

const childrenSelector = selector<Child[]>({
  key: RecoilSelectorKeys.Children,
  get: ({ get }) => {
    const childIds = get(childIdsAtom);
    return childIds.map((id) => get(childAtomFamily(id)));
  },
});

const useSetFirstLogin = () =>
  useRecoilCallback(
    ({ set }) =>
      (childId: ChildId, isFirstLogin: boolean) => {
        set(childFirstLoginAtomFamily(childId), isFirstLogin);
      },
    [],
  );

const useBulkInsert = () =>
  useRecoilCallback(
    ({ set }) =>
      (children: Child[]) => {
        const childIds: ChildId[] = [];
        for (const child of children) {
          set(childAtomFamily(child.child_id), child);
          childIds.push(child.child_id);
        }
        set(childIdsAtom, childIds);
      },
    [],
  );

const useSet = () =>
  useRecoilCallback(
    ({ set }) =>
      (child: Child) => {
        set(childAtomFamily(child.child_id), child);
        set(childIdsAtom, (prev) =>
          prev.includes(child.child_id) ? prev : [...prev, child.child_id],
        );
      },
    [],
  );

const useRemove = () =>
  useRecoilCallback(
    ({ set, reset }) =>
      (childId: ID) => {
        reset(childAtomFamily(childId));
        set(childIdsAtom, (prev) => prev.filter((v) => v !== childId));
      },
    [],
  );

const useValue = (child_id: ChildId) => useRecoilValue(childAtomFamily(child_id));
const useIsFirstLogin = (child_id: ChildId) => useRecoilValue(childFirstLoginAtomFamily(child_id));
const useChildrenValues = () => useRecoilValue(childrenSelector);

export const childActions = { useBulkInsert, useSet, useRemove, useSetFirstLogin };
export const childGetters = { useValue, useChildrenValues, useIsFirstLogin };
