import styled from 'styled-components';
import { colors } from '~/styles/ts/colors';
import { fontFamilies, pxVw } from '~/styles/ts/functions';
import { path } from '~/utils/path';

const Illustration = styled.img`
  margin: 0 auto;
  width: ${pxVw.SP(480)};
  height: ${pxVw.SP(320)};
  margin-bottom: ${pxVw.SP(40)};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
`;

const Message = styled.p`
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  font-family: ${fontFamilies.asics};
  letter-spacing: 0;
  line-height: 1.4;
  color: ${colors.text.main};
`;

export const ForbiddenPage = () => {
  return (
    <Container>
      <Content>
        <Illustration src={path.image('/others/illust_forbidden.png')} />
        <Message>
          This site is currently not available <br />
          in this country or region.
        </Message>
      </Content>
    </Container>
  );
};
