import { useCallback, useState } from 'react';
import type { CancelableAxiosError } from '~/interfaces/api';

export const useRequestApi = <P, R>(
  requestFn: (params: P) => Promise<R>,
  onCommonSuccess?: (res: R) => void,
  onCommonError: (e?: CancelableAxiosError) => void = () => {
    console.error('エラーが発生しました');
  },
) => {
  const [isApiActivating, setIsApiActivating] = useState<boolean>(false);

  return useCallback(
    async (
      requestParams: P,
      onSuccess?: (res: R) => void | Promise<void>,
      onError?: (err?: CancelableAxiosError) => void | Promise<void>,
      onFinally?: () => void,
    ) => {
      if (isApiActivating) return;

      setIsApiActivating(true);

      try {
        const res = await requestFn(requestParams);
        onCommonSuccess && onCommonSuccess(res);
        await (onSuccess && onSuccess(res));
        return res;
      } catch (e: any) {
        onCommonError(e);
        await (onError && onError(e));
      } finally {
        setIsApiActivating(false);
        onFinally && onFinally();
      }
    },
    [isApiActivating, onCommonError, onCommonSuccess, requestFn],
  );
};
