import type {
  PostAccountEditMailReminderRequest,
  PostAccountEditMailReminderResponse,
} from '~/api/aspida/@types';
import { sendRequest } from '~/api/sendRequest';

export const postAccountEditMailreminder = sendRequest<
  PostAccountEditMailReminderRequest,
  PostAccountEditMailReminderResponse
>({
  url: '/account/edit/mail_reminder',
  method: 'post',
});
