import { getAccount } from '~/api/account';
import { postAccountCampaign } from '~/api/account/campaign';
import { putAccountEdit } from '~/api/account/edit';
import { postAccountEditMailreminder } from '~/api/account/edit/mailReminder';
import { postAccountRegister } from '~/api/account/register';
import type { PostAccountCampaignRequest } from '~/api/aspida/@types';
import { accountServices } from '~/features/account/accountService';
import { useRequestApi } from '~/hooks/useRequestApi';
import { entryPersistences } from '~/persistence';
import { childActions } from '~/recoil/child';
import { userActions } from '~/recoil/user';

export const useAccountApi = () => {
  const setUser = userActions.useSet();
  const setChildren = childActions.useBulkInsert();

  const getAccountRequest = useRequestApi(getAccount, (res) => {
    const user = accountServices.userAdapter(res);
    const children = accountServices.childrenAdapter(res);

    setUser(user);
    if (children.length) {
      setChildren(children);
    }
  });

  const postAccountRegisterRequest = useRequestApi(postAccountRegister, (res) => {
    const user = accountServices.userAdapter(res);

    setUser(user);
  });

  const putAccountEditRequest = useRequestApi(putAccountEdit, (res) => {
    const user = accountServices.userAdapter(res);

    setUser(user);
  });

  const postAccountEditMailreminderRequest = useRequestApi(postAccountEditMailreminder, (res) => {
    const user = accountServices.userAdapter(res);

    setUser(user);
  });

  const postAccountCampaignRequest = useRequestApi(postAccountCampaign, ({ conversion }) => {
    const _persist = entryPersistences.PostedCampaigns.value || {};
    entryPersistences.PostedCampaigns.set({
      ..._persist,
      [conversion]: true,
    });
  });

  const postAccountCampaignRequestConversion = async (
    conversion: 'checkin' | 'measure' | 'artwork',
  ) => {
    const postedCampaigns = entryPersistences.PostedCampaigns.value || {};
    const campaignTag = entryPersistences.CampaignTag.value;
    const utmParams = entryPersistences.Campaigns.value;
    if (postedCampaigns[conversion]) return;
    if (!campaignTag || !utmParams) return;
    const params: PostAccountCampaignRequest = {
      campaign_tag: campaignTag,
      utm_campaign: utmParams.utm_campaign,
      utm_source: utmParams.utm_source,
      utm_medium: utmParams.utm_medium,
      conversion,
    };

    await postAccountCampaignRequest(params);
  };

  return {
    getAccountRequest,
    postAccountRegisterRequest,
    putAccountEditRequest,
    postAccountEditMailreminderRequest,
    postAccountCampaignRequestConversion,
  };
};
