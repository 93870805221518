import type { DateObjectUnits } from 'luxon';
import { DateTime } from 'luxon';
import type { DateObj, DateString, DateTimeString, UnixTime } from '~/interfaces/common';

type FormatType = 'yyyy-MM-dd' | 'y/M/d' | 'M/d' | 'yyyy/MM' | 'y-MM-dd';

/**
 * 誕生日から生後何ヶ月かを返す
 * @param birthdayTime {UnixTime} UnixTime (秒数)
 * @param unixTime {UnixTime} UnixTime (秒数)
 * @returns {number} 月数
 * @example
 * const birthday = 1609459200;
 * const now = 1612137600;
 * const months = monthDiff(birthday, now);
 * console.log(months); // 1
 */
export const monthDiff = (birthdayTime: number, unixTime: number): number => {
  const birthday: DateTime = DateTime.fromSeconds(birthdayTime);
  const time: DateTime = DateTime.fromSeconds(unixTime);
  let months: number = (time.year - birthday.year) * 12;
  months -= birthday.month;
  months += time.month;
  return months <= 0 ? 0 : months;
};

/**
 * 現在の日付を返す
 * @returns {DateTime} DateTime
 * @example
 * const dt = currentDateTime();
 * console.log(dt.toFormat('yyyy-MM-dd')); // 2021-01-01
 */
export const currentDateTime = () => {
  return DateTime.now();
};

/**
 * 現在の年を返す
 * @returns {number} 年
 * @example
 * const year = currentYear();
 * console.log(year); // 2021
 */
export const currentYear = () => {
  const dt = DateTime.now();
  return dt.year;
};

/**
 * 現在の月を返す
 * @returns {number} 月
 * @example
 * const month = currentMonth();
 * console.log(month); // 1
 */
export const currentMonth = () => {
  const dt = DateTime.now();
  return dt.month;
};

/**
 * 現在の日付をUnixTimeで返す
 * @returns {number} UnixTime (秒数)
 * @example
 * const seconds = currentSeconds();
 * console.log(seconds); // 1609459200
 */
export const getCurrentSeconds = () => {
  return DateTime.now().toSeconds();
};

/**
 * seconds(秒数)からDateTimeを返す
 * @param date {UnixTime} UnixTime (秒数)
 * @returns {DateTime} DateTime
 * @example
 * const seconds = 1609459200;
 * const dt = dateTimeFromSeconds(seconds);
 * console.log(dt.toFormat('yyyy-MM-dd')); // 2021-01-01
 */
export const dateTimeFromSeconds = (date: number): DateTime => {
  return DateTime.fromSeconds(date);
};

/**
 * SQL形式の文字列を受け取り、DateTimeを返す
 * @param date {DateTimeString} 'yyyy-mm-dd' | 'yyyy-mm-dd hh:mm:ss'
 * @returns {DateTime} DateTime
 * @example
 * const date = '2021-01-01';
 * const dt = dateTimeFromSQL(date);
 * console.log(dt.toFormat('yyyy-MM-dd')); // 2021-01-01
 */
export const dateTimeFromSQL = (date: string): DateTime => {
  return DateTime.fromSQL(date);
};

/**
 * DateObjectUnitsからDateTimeを返す
 * @param date {DateObjectUnits} DateObjectUnits
 * @returns {DateTime} DateTime
 * @example
 * const dateObj = { year: 2021, month: 1, day: 1 };
 * const dt = dateTimeFromObject(dateObj);
 * console.log(dt.toFormat('yyyy-MM-dd')); // 2021-01-01
 */
export const dateTimeFromObject = (date: DateObjectUnits): DateTime => {
  return DateTime.fromObject(date);
};

/**
 * yyyy/mm形式を受け取り、DateTimeを返す
 * @param date {DateString} 'yyyy/mm'
 * @returns {DateTime} DateTime
 * @example
 * const date = '2021/01';
 * const dt = dateTimeFromFormattedDate(date);
 * console.log(dt.toFormat('yyyy-MM-dd')); // 2021-01-01
 */
export const dateTimeFromFormattedDate = (date: string): DateTime => {
  return DateTime.fromFormat(date, 'yyyy/MM');
};

/**
 * DateObjからyyyy-MM-dd形式にフォーマットされた日付を返す
 * @param dateObj {DateObj} DateObj
 * @returns {string} yyyy-MM-dd形式にフォーマットされた日付
 * @example
 * const dateObj = { year: 2021, month: 1, day: 1 };
 * const dateString = dateStringFromDateObj(dateObj);
 * console.log(dateString); // 2021-01-01
 */
export const dateStringFromDateObj = (dateObj: DateObj): DateString => {
  const dt = DateTime.fromObject(dateObj);
  return dt.toFormat('yyyy-MM-dd');
};

/**
 * 秒数からyyyy-MM-dd形式にフォーマットされた日付を返す
 * @param seconds {number} 秒数
 * @returns {string} yyyy-MM-dd形式にフォーマットされた日付
 * @example
 * const seconds = 1609459200;
 * const dateString = dateStringFromSeconds(seconds);
 * console.log(dateString); // 2021-01-01
 */
export const dateStringFromSeconds = (seconds: number): DateString => {
  const dt = DateTime.fromSeconds(seconds);
  return dt.toFormat('yyyy-MM-dd');
};

/**
 * 秒数からフォーマットされた日付を返す
 * @param seconds {number} 秒数
 * @param formatType {FormatType} フォーマットの種類
 * @returns {string} フォーマットされた日付
 * @example
 * const seconds = 1609459200;
 * const dateString = dateFormatFromSeconds(seconds, 'yyyy/MM/dd');
 * console.log(dateString); // 2021/01/01
 */
export const dateFormatFromSeconds = (seconds: number, formatType: FormatType = 'yyyy-MM-dd') => {
  const dt = DateTime.fromSeconds(seconds);
  return dt.toFormat(formatType);
};

/**
 * DateObjからフォーマットされた日付を返す
 * @param date {DateObj} DateObj
 * @param formatType {FormatType} フォーマットの種類
 * @returns {string} フォーマットされた日付
 * @example
 * const date = { year: 2021, month: 1, day: 1 };
 * const dateString = dateFormatFromDateObj(date, 'yyyy/MM/dd');
 * console.log(dateString); // 2021/01/01
 */
export const dateFormatFromDateObj = (date: DateObj, formatType: FormatType = 'yyyy-MM-dd') => {
  const dt = DateTime.fromObject(date);
  return dt.toFormat(formatType);
};

/**
 * 秒数からM/d形式にフォーマットされた日付を返す
 * @param seconds {number} 秒数
 * @returns {string} M/d形式にフォーマットされた日付
 * @example
 * const seconds = 1609459200;
 * const dateString = monthDayStringFromSeconds(seconds);
 * console.log(dateString); // 1/1
 */
const _monthDayStringFromSeconds = (seconds: number): DateString => {
  const dt = DateTime.fromSeconds(seconds);
  return dt.toFormat('M/d');
};

/**
 * SQL形式の文字列を受け取り、UnixTimeに変換して返す
 * @param date {DateString} 'yyyy-mm-dd' | 'yyyy-mm-dd hh:mm:ss'
 * @returns {UnixTime} UnixTime (秒数)
 * @example
 * const date = '2021-01-01';
 * const seconds = unixTimeFromSQL(date);
 * console.log(seconds); // 1609459200
 */
export const unixTimeFromSQL = (date: DateString | DateTimeString): UnixTime => {
  const dt = DateTime.fromSQL(date);
  return dt.toSeconds();
};

/**
 * UnixTimeを受け取り、DateObjに変換して返す
 * @param time UnixTime (秒数)
 * @returns {DateObj} DateObj
 * @example
 * const time = 1609459200;
 * const dateObj = DateObjFromUnixTime(time);
 * console.log(dateObj); // { year: 2021, month: 1, day: 1 }
 */
export const DateObjFromUnixTime = (time: UnixTime): DateObj => {
  const dt = DateTime.fromSeconds(time);
  const obj = dt.toObject();
  return {
    year: obj.year,
    month: obj.month,
    day: obj.day,
  };
};

/**
 * yyyy-mm-dd形式を受け取り、DateObjに変換して返す
 * @param date {DateString} 'yyyy-mm-dd'
 * @returns {DateObj} DateObj
 * @example
 * const date = '2021-01-01';
 * const dateObj = toDateObj(date);
 * console.log(dateObj); // { year: 2021, month: 1, day: 1 }
 */
export const toDateObj = (date: DateString | DateTimeString): DateObj => {
  return DateObjFromUnixTime(unixTimeFromSQL(date));
};

/**
 * 現在の日付をyyyy-mm-dd形式で返す
 * @returns {DateString} 'yyyy-mm-dd'
 * @example
 * const date = nowDate();
 * console.log(date); // 2021-01-01
 */
export const nowDate = (): DateString => {
  const dt = DateTime.now();
  return dt.toFormat('yyyy-MM-dd');
};

/**
 * dateが今日かどうかを返す
 * @param date {DateString} 'yyyy-mm-dd'
 * @returns {boolean} 今日かどうか
 * @example
 * const date = '2021-01-01';
 * const isToday = checkToday(date);
 * console.log(isToday); // false
 */
export const checkToday = (date: DateString) => {
  return DateTime.fromSQL(date).hasSame(DateTime.now(), 'day');
};

/**
 * dateAとdateBが同じ月かどうかを返す
 * @param dateA {DateString} 'yyyy-mm-dd'
 * @param dateB {DateString} 'yyyy-mm-dd'
 * @returns {boolean} 同じ月かどうか
 * @example
 * const dateA = '2021-01-01';
 * const dateB = '2021-02-01';
 * const isSameMonth = checkSameMonth(dateA, dateB);
 * console.log(isSameMonth); // false
 */
export const checkSameMonth = (dateA: DateString, dateB: DateString) => {
  return DateTime.fromSQL(dateA).hasSame(DateTime.fromSQL(dateB), 'month');
};

/**
 * dateAとdateBが同じ年かどうかを返す
 * @param dateA {DateString} 'yyyy-mm-dd'
 * @param dateB {DateString} 'yyyy-mm-dd'
 * @returns {boolean} 同じ年かどうか
 * @example
 * const dateA = '2021-01-01';
 * const dateB = '2021-01-02';
 * const isSameYear = checkSameYear(dateA, dateB);
 * console.log(isSameYear); // true
 */
export const checkSameYear = (dateA: DateString, dateB: DateString) => {
  return DateTime.fromSQL(dateA).hasSame(DateTime.fromSQL(dateB), 'year');
};

/**
 * dateが今日以降の日付かどうかを返す
 * @param date {DateString} 'yyyy-mm-dd'
 * @returns {boolean} 今日以降の日付かどうか
 * @example
 * const date = '2021-01-01';
 * const isFutureDate = checkFutureDate(date);
 * console.log(isFutureDate); // false
 */
export const checkPassedDate = (date: DateString) => {
  return DateTime.fromSQL(date).diffNow('days').days <= 0;
};

/**
 * 誕生日から年齢と月齢を返す
 * @param birthday {UnixTime} 誕生日
 * @returns {Object} 年齢と月齢
 * @example
 * const birthday = 1609459200;
 * const { age, month } = getAgeAndMonthFromBirthday(birthday);
 * console.log(age); // 0
 */
export const getAgeAndMonthFromBirthday = (birthday: UnixTime) => {
  const birth = DateTime.fromSeconds(Number(birthday));
  const now = DateTime.now();
  const diff = now.diff(birth, ['years', 'months']);
  const age = Math.floor(diff.years);
  const month = Math.floor(diff.months);
  return { age, month };
};

/**
 * 月の数字から月の短縮名を返す
 * @param month {number} 月の数字
 * @returns {string} 月の短縮名
 * @example
 * const month = 1;
 * const shortMonthName = getShortMonthNameFromNumber(month);
 * console.log(shortMonthName); // Jan
 */
export const getShortMonthNameFromNumber = (month: number) => {
  return DateTime.fromObject({ month: month }).toFormat('LLL');
};

/*
 * 現在から指定した日付までの差がdaysThreshold日以内かどうかを返す。
 * @param targetDateTime {number} 指定した日付（timestamp）
 * @param daysThreshold {number} 期間 (日数)
 * @returns {boolean} 期間内かどうか
 * @example
 * const targetDateTime = 1609459200;
 * const daysThreshold = 30;
 * const isWithinDays = isWithinDaysFromNow(targetDateTime, daysThreshold);
 * console.log(isWithinDays); // true
 */
export const isWithinDaysFromNow = (targetDateTime: number, daysThreshold: number) => {
  const currentDateTime = DateTime.now();
  const targetDateTimeObj = DateTime.fromMillis(targetDateTime);

  const daysDifference = currentDateTime.diff(targetDateTimeObj).as('days');

  return daysDifference < daysThreshold;
};
