import type {
  GetAccountResponse,
  PostAccountEditMailReminderResponse,
  PostAccountRegisterResponse,
  PutAccountEditResponse,
} from '~/api/aspida/@types';
import { kidsServices } from '~/features/account/child/childAdapter';
import type { Child } from '~/features/account/child/interfaces/models';
import { Color, type ColorType } from '~/features/account/color';
import { Gender, type GenderType } from '~/features/account/gender';
import { prefectureKeysFromAreaId } from '~/features/account/prefecture/utils';
import {
  getRelationshipData,
  getRelationshipKey,
  type RelationshipType,
} from '~/features/account/relationship';
import { Sports, type SportsType } from '~/features/account/sports/SportsType';
import type { User } from '~/features/account/user';
import { ShoeSize } from '~/features/size/size';
import type { Version } from '~/interfaces/common';

const FREE_TEXT_PREFECTURE_INDEX = 1;

const RelationshipToNumberEnum = (r: RelationshipType): number => {
  return getRelationshipKey(r);
};

const SportToNumberEnum = (s: SportsType): number => {
  return Sports.indexOf(s);
};

const ColorToNumberEnum = (c: ColorType): number => {
  return Color.indexOf(c) + 1;
};

const GenderFreeIndex = Gender.length - FREE_TEXT_PREFECTURE_INDEX;

const isGenderFree = (g: GenderType): boolean => {
  return Gender.indexOf(g) === GenderFreeIndex;
};

const GenderToNumberEnum = (g: GenderType): number => {
  const i = Gender.indexOf(g);
  return i === GenderFreeIndex ? 0 : i + 1;
};

const EnumToGender = (n: number): GenderType => {
  if (n === 0) {
    return Gender[GenderFreeIndex];
  } else {
    return Gender[n - 1];
  }
};

const sportsListFromSportIds = (sportIds: number[]) => {
  return sportIds.map((id) => Sports[id]);
};

const userAdapter = (
  data:
    | GetAccountResponse
    | PostAccountRegisterResponse
    | PutAccountEditResponse
    | PostAccountEditMailReminderResponse,
): User => {
  const user: User = {
    asics_id: data.asics_id,
    name: data.name,
    relationship: getRelationshipData(data.relationship),
    relationship_text: data.relationship_text,
    shoe_size: new ShoeSize(data.shoe_size),
    prefecture: prefectureKeysFromAreaId(data.prefecture),
    prefecture_text: data.prefecture_text,
    icon: data.icon,
    latest_terms_optin_version: data.latest_terms_optin_version as Version,
    has_tutorial: data.has_tutorial,
    has_account_registered: data.has_account_registered,
    selected_child_id: data.selected_child_id,
    mail_reminder_optin: data.mail_reminder_optin,
  };

  return user;
};

const childrenAdapter = (data: GetAccountResponse): Child[] => {
  const children: Child[] = [];

  for (const childData of data.children) {
    const child: Child = kidsServices.childAdapter(childData);

    children.push(child);
  }

  return children;
};

export const accountServices = {
  RelationshipToNumberEnum,
  SportToNumberEnum,
  ColorToNumberEnum,
  GenderToNumberEnum,
  EnumToGender,
  sportsListFromSportIds,
  isGenderFree,
  userAdapter,
  childrenAdapter,
};
