export const NavRoutes = <const>{
  Artwork: '/artwork',
  Home: '/home',
  Events: '/events',
  Record: '/record',
  Stamps: '/stamps',
  Measure: '/measure',
  FAQ: '/faq',
  Entry: '/',
  Register: '/register',
  Cloakroom: '/cloakroom',
  Withdraw: '/withdraw',
  Withdrawn: '/withdrawn',
  Gallery: '/gallery',
  Scan: '/mfid',
  ScanResult: '/result',
};

export type NavRoutes = (typeof NavRoutes)[keyof typeof NavRoutes];
